<template>
    <section class="my-article-section">
        <div class="container my-container">
            <div class="my-article-content">
                <div class="row no-gutters">
                    <div class="col-sm-12">
                    <h4>My Articles</h4>
                    </div>
                    <div class="col-md-3">
                    <form class="d-flex">
                        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                    </form>
                    </div>
                    <div class="col-md-3">
                    </div>
                    <div class="col-md-3">
                    <div class="mb-3 row">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Sort</label>
                        <div class="col-sm-10">
                        <select class="js-example-basic-single" name="zipcode" style="width: 100%;">
                            <option value="AL">All</option>
                            <option value="WY">Hardcover</option>
                            <option value="WY">Paperback</option>
                            <option value="WY">Other Article</option>
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-3">
                    <div class="mb-3 row">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Filter</label>
                        <div class="col-sm-10">
                        <select class="js-example-basic-single" name="zipcode" style="width: 100%;">
                            <option value="AL">All</option>
                            <option value="RF">Red Flag</option>
                            <option value="AA">Available</option>
                            <option value="IC">In-Circulation</option>
                            <option value="AC">Aquaried</option>
                        </select>
                        </div>
                    </div>
                    </div>
                </div>
                
                <div class="my-article-container">
                    <div class="item-article">
                        <div class="row no-gutters">
                        <div class="col-sm-2">
                            <a class="item-href-img">
                            <div class="img-profile-container"></div>
                            </a>
                        </div>
                        <div class="col-sm-10">
                            <div class="row no-gutters">
                            <div class="col-md-9">
                                <h3 class="a_name">
                                The Action Bible Guess-It Game
                                <span class="red-flag-container">
                                    <i class="fas fa-flag"></i>
                                    <i class="fas fa-flag"></i>
                                </span>
                                </h3>
                                <p class="a_author">
                                by Sally Lloyd-Jones
                                </p>
                                <div class="a_rating">
                                <ul>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="far fa-star"></i></li>
                                </ul>
                                </div>
                                <div class="stats-data">
                                <div class="a_status available">
                                    <i class="fas fa-circle"></i> <span>Available</span>
                                </div>
                                <div class="prev-borrower">
                                    <p>Previous Borrower : <span>John Doe</span></p>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="a_des">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Lorem sed risus ultricies tristique nulla aliquet enim tortor. Nascetur ridiculus mus mauris vitae ultricies leo integer malesuada. </p>
                                </div>
                                <div class="a-soc-stats">
                                <ul>
                                    <li class="count-like">22 Likes</li>
                                    <li class="count-review">33 Review</li>
                                    <li class="count-share">44 Share</li>
                                </ul>
                                </div>
                                <div class="d-grid gap-2 d-md-block btn-grp-my-art">
                                <button class="btn btn-edit" type="button">Edit Article</button>
                                <button class="btn btn-review" type="button">Write/Edit Review</button>
                                <button class="btn btn-request" type="button">Return Request</button>
                                <button class="btn btn-del" type="button" data-bs-toggle="modal" data-bs-target="#modal_delete">Delete Article</button>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <hr>
                    <div class="item-article">
                        <div class="row no-gutters">
                        <div class="col-sm-2">
                            <a class="item-href-img">
                            <div class="img-profile-container"></div>
                            </a>
                        </div>
                        <div class="col-sm-10">
                            <div class="row no-gutters">
                            <div class="col-md-9">
                                <h3 class="a_name">
                                The Action Bible Guess-It Game
                                </h3>
                                <p class="a_author">
                                by Sally Lloyd-Jones
                                </p>
                                <div class="a_rating">
                                <ul>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="far fa-star"></i></li>
                                </ul>
                                </div>
                                <div class="stats-data">
                                <div class="a_status acquired">
                                    <i class="fas fa-circle"></i> <span>Acquired </span>
                                </div>
                                <div class="current-borrower">
                                    <p>Current Borrower : <span>Rhiana Escomo</span></p>
                                </div>
                                <div class="prev-borrower">
                                    <p>Previous Borrower : <span>Cecillia Deloy</span></p>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="a_des">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Lorem sed risus ultricies tristique nulla aliquet enim tortor. Nascetur ridiculus mus mauris vitae ultricies leo integer malesuada. </p>
                                </div>
                                <div class="a-soc-stats">
                                <ul>
                                    <li class="count-like">22 Likes</li>
                                    <li class="count-review">33 Review</li>
                                    <li class="count-share">44 Share</li>
                                </ul>
                                </div>
                                <div class="d-grid gap-2 d-md-block btn-grp-my-art">
                                <button class="btn btn-edit" type="button">Edit Article</button>
                                <button class="btn btn-review" type="button">Write/Edit Review</button>
                                <button class="btn btn-request" type="button" data-bs-toggle="modal" data-bs-target="#modal_return">Return Request</button>
                                <button class="btn btn-del" type="button" data-bs-toggle="modal" data-bs-target="#modal_delete">Delete Article</button>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <hr>
                    <div class="item-article">
                        <div class="row no-gutters">
                        <div class="col-sm-2">
                            <a class="item-href-img">
                            <div class="img-profile-container"></div>
                            </a>
                        </div>
                        <div class="col-sm-10">
                            <div class="row no-gutters">
                            <div class="col-md-9">
                                <h3 class="a_name">
                                The Action Bible Guess-It Game
                                </h3>
                                <p class="a_author">
                                by Sally Lloyd-Jones
                                </p>
                                <div class="a_rating">
                                <ul>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="far fa-star"></i></li>
                                </ul>
                                </div>
                                <div class="stats-data">
                                <div class="a_status in-circulation">
                                    <i class="fas fa-circle"></i> <span>In - Circulation</span>
                                </div>
                                <div class="current-borrower">
                                    <p>Current Borrower : <span>Rhiana Escomo</span></p>
                                </div>
                                <div class="prev-borrower">
                                    <p>Previous Borrower : <span>Cecillia Deloy</span></p>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="a_des">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Lorem sed risus ultricies tristique nulla aliquet enim tortor. Nascetur ridiculus mus mauris vitae ultricies leo integer malesuada. </p>
                                </div>
                                <div class="a-soc-stats">
                                <ul>
                                    <li class="count-like">22 Likes</li>
                                    <li class="count-review">33 Review</li>
                                    <li class="count-share">44 Share</li>
                                </ul>
                                </div>
                                <div class="d-grid gap-2 d-md-block btn-grp-my-art">
                                <button class="btn btn-edit" type="button">Edit Article</button>
                                <button class="btn btn-review" type="button">Write/Edit Review</button>
                                <button class="btn btn-request" type="button"  data-bs-toggle="modal" data-bs-target="#modal_borrower">Return Request</button>
                                <button class="btn btn-del" type="button" data-bs-toggle="modal" data-bs-target="#modal_delete">Delete Article</button>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <nav aria-label="Page navigation" class="pagination-member">
                    <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                        <i class="fas fa-chevron-left"></i>
                        </a>
                    </li>
                    <li class="page-item "><a class="page-link active" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                        <i class="fas fa-chevron-right"></i>
                        </a>
                    </li>
                    </ul>
                </nav>
            </div>
        </div>
    </section>
</template>